var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

let ismobile = false;
let openMega = null;
let currentPage = 1;
let isplaying = false;
let elemets = null;
let axolast = [];
let axos = [];
let MinValueMiete, MaxValueMiete, MinValueArea, MaxValueArea;
let $myfilter = {};
let merkliste = [];
let settingsOpen, settingsChange = false;
if (md.mobile())
    ismobile = true;


var $pathn = location.pathname.split('/').join('');
if (Cookies.get('merkliste' + $pathn))
    merkliste = JSON.parse(Cookies.get('merkliste' + $pathn));

console.log(merkliste)
if (!merkliste)
    merkliste = [];
// scrollmagic init
let ctrl = new ScrollMagic.Controller();

// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    var a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (var i = 0; i < a.length; i++) {
        var href = a[i].host; // set the host of each link
        if ((!internal.test(href)&& !a[i].classList.contains('haus-link') && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}

function hideDetailviews() {
    for (var $z = 0; $z < axolast.length; $z++) {
        if (axolast[$z]) {
            let $innerDiv = axolast[$z].getElementsByClassName("inner-div")[0];
            let $btn = axolast[$z].getElementsByClassName("btn-open")[0];
            axolast[$z].classList.remove("open");
            $btn.classList.remove("active");
            anime({
                targets: $innerDiv,
                maxHeight: 0,
                easing: 'linear',
                duration: 0,
                complete: function (anim) {
                }
            });
            axolast[$z] = null;
        }
    }
}

function addToList(id) {


    if (merkliste.indexOf(id) === -1) {
        merkliste.push(id);
    } else {
        merkliste = merkliste.filter(item => item !== id)
    }

    Cookies.set('merkliste' + $pathn, JSON.stringify(merkliste), {expires: 1});
    document.querySelector('.badge-merk').innerText = merkliste.length;

}

function checkItems(gl) {
    if (gl.slides_count <= gl.settings.perView) {
        gl.update({startAt: 0}).disable();
        gl.elem.querySelectorAll('.glide__arrows').forEach((el) => {
            el.classList.add('d-none')
        });
        gl.elem.querySelectorAll('.glide__bullets').forEach((el) => {
            el.classList.add('d-none')
        });
    } else {
        gl.enable();
        gl.elem.querySelectorAll('.glide__arrows').forEach((el) => {
            el.classList.remove('d-none')
        });
        gl.elem.querySelectorAll('.glide__bullets').forEach((el) => {
            el.classList.remove('d-none')
        });
    }
}



function refreshfilter() {
    console.log('refreshfilter')
    var $fmin = parseFloat(document.querySelector('#flaeche-slider-lower-value').innerHTML.replace('m²', ''));
    var $fmax = parseFloat(document.querySelector('#flaeche-slider-upper-value').innerHTML.replace('m²', ''));

    var $pmin = parseFloat(document.querySelector('#miete-slider-lower-value').innerHTML.replace('CHF ', ''));
    var $pmax = parseFloat(document.querySelector('#miete-slider-upper-value').innerHTML.replace('CHF ', ''));

    [].forEach.call(document.querySelectorAll('.house-section'), function ($elS, indexS) {
        var num = 0;
        [].forEach.call(document.querySelectorAll('.haus' + indexS + ' li.table-row'), function ($el, index) {
            var $result = true;
            var $result2 = false;
            var $result3 = false;
            // check filters
            Object.keys($myfilter).forEach(key => {
                var $fname = key;
                var $fvar = $myfilter[key];
                var i, $resultI = false;

                for (var $i = 0; $i < $fvar.length; $i++) {
                    if ($fname === 'status') {
                        if ($el.querySelectorAll('.col-1')[0].dataset.nstatus === $fvar[$i]) {
                            $resultI = true;
                        }
                    } else if ($fname === 'pinned') {
                        if ($el.classList.contains('pinned')) {
                            $resultI = true;
                        }
                    } else {
                        var tda = $el.querySelectorAll('.' + $fname)[0].innerHTML;
                        //console.log(tda + ' = ' + $fvar[$i]);
                        if (tda === $fvar[$i]) {
                            $resultI = true;
                        }
                    }
                }

                if ($fvar.length === 0)
                    $resultI = true;
                // console.log($fvar.length);

                if (!$resultI)
                    $result = false;
            });

            // check fläche
            let tdp = $el.querySelectorAll('.col-' + $flaecheID)[0].innerHTML;
            let tdpf = parseFloat(tdp.replace(' ', ''));


            if ($result) {
                $result2 = $fmin <= tdpf && tdpf <= $fmax;
            }

            // check preisrange
            let tdp2 = $el.querySelectorAll('.col-' + +$preisID)[0].innerHTML;
            let tdpf2 = parseFloat(tdp2.replace('CHF ', '').replace(' ', '').replace(',-', '').replace('\'', ''));


            if ($result) {
                $result3 = $pmin <= tdpf2 && tdpf2 <= $pmax;
            }


            //console.log($el)
            //console.log(tdpf2);
            //console.log(tdpf);
            //console.log($result + ' ' + $result2 + ' ' + $result3);
            // hide if false
            if (!$result || !$result2 || !$result3) {
                $el.classList.add('hide');
            } else {
                $el.classList.remove('hide');
                if (num % 2 === 0) {
                    //$el.classList.remove('gray');
                } else {
                    //$el.classList.add('gray');
                }
                num++;
            }
        });
    });
}

function initBanner(){
    // Init banner Sliders
    document.querySelectorAll('.glide-banner').forEach((elem) => {
        // console.log('mount2');
        var slides_count = document.querySelectorAll('.glide-banner .glide__slide').length;
        var type = 'slider';
        var autoplay = false;
        if (slides_count > 1)
            autoplay = 7000
        var gl = new Glide(elem, {
            perView: 1,
            type: type,
            autoplay: autoplay,
            hoverpause: false,
            bound: true
        });
        gl.slides_count = slides_count;
        gl.elem = elem;
        gl.on('run.after', () => {
            animate_slide(gl.index)
            checkItems(gl)
        })
        gl.on('mount.after', function () {
            setTimeout(function () {
                animate_slide(gl.index)
            }, 1000)
            checkItems(gl)
        });
        gl.on('resize', function () {
            checkItems(gl)
        });

        gl.mount()


    });
}


function animate_slide(id) {

    console.log('run');
    var slinit = anime.timeline({
        easing: 'linear'
    });

    slinit.add({
        targets: '.banner svg.muster .shape',
        opacity: 0,
        duration: 10,
    });
    slinit.add({
        targets: '.banner .claim',
        opacity: 0,
        easing: 'easeInOutSine',
        duration: 110
    }, '-=110');
    slinit.add({
        targets: '.banner .claim2',
        opacity: 0,
        easing: 'easeInOutSine',
        duration: 110
    }, '-=110');
    slinit.add({
        targets: '.banner .claim .line1',
        opacity: 0,
        marginLeft: '-10%',
        easing: 'easeInOutSine',
        duration: 10
    }, '-=10');

    slinit.add({
        targets: '.banner .claim .line2',
        opacity: 0,
        marginLeft: '-10%',
        easing: 'easeInOutSine',
        duration: 10
    }, '-=10');
    slinit.add({
        targets: '.banner .claim .line3',
        opacity: 0,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 10
    }, '-=10');

    slinit.add({
        targets: '.banner .claim .line4',
        opacity: 0,
        marginLeft: '-10%',
        easing: 'easeInOutSine',
        duration: 10
    }, '-=10');

    slinit.add({
        targets: '.banner .claim .btn',
        opacity: 0,
        marginLeft: '-10%',
        easing: 'easeInOutSine',
        duration: 10
    }, '-=10');

    slinit.add({
        targets: '.glslide' + id + ' .claim',
        opacity: 1,
        easing: 'easeInOutSine',
        duration: 150
    }, '+=100');


    slinit.add({
        targets: '.glslide' + id + ' .claim .line1',
        opacity: 1,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 350
    }, '-=100');
    slinit.add({
        targets: '.glslide' + id + ' .claim .line2',
        opacity: 1,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 350
    }, '-=100');
    slinit.add({
        targets: '.glslide' + id + ' .claim .line3',
        opacity: 1,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 350
    }, '-=100');
    slinit.add({
        targets: '.glslide' + id + ' .claim .line4',
        opacity: 1,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 350
    }, '-=100');
    slinit.add({
        targets: '.glslide' + id + ' .claim .btn',
        opacity: 1,
        marginLeft: '0%',
        easing: 'easeInOutSine',
        duration: 350
    }, '-=100');

    slinit.play();
}

(function () {
    "use strict";

    // Run the function targetBlank()
    targetBlank();
    if (document.querySelectorAll(".scroll-top").length > 0) {
        document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
        });
    }

    if (document.querySelectorAll(".intro-presite").length > 0) {
        // fadein main
        console.log("tiinit")
        var tiinit = anime.timeline({
            easing: 'linear',
            complete: function(anim) {

            }
        });
        anime({
            targets: '.intro-presite .claim-text h4',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        anime({
            targets: '.intro-presite .claim-text h1',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        anime({
            targets: '.intro-presite .claim-text h3',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        anime({
            targets: '.intro-presite .bottom',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        tiinit.add({
            targets: '.intro-presite .logo',
            opacity: 1,
            scale: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=0');

        tiinit.add({
            targets: '.intro-presite .claim-text h4',
            opacity: 1,
            scale: {
                value: 1
            },
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=0');

        tiinit.add({
            targets: '.intro-presite .claim-text h1',
            opacity: 1,
            scale: {
                value: 1
            },
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=0');

        tiinit.add({
            targets: '.intro-presite .claim-text h3',
            opacity: 1,
            scale: 1,
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=0');
        tiinit.add({
            targets: '.intro-presite .bottom',
            opacity: 1,
            scale: 1,
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=200');
        tiinit.play();




    }

    if (document.querySelectorAll(".intro").length > 0) {
        // fadein main
        console.log("tiinit")
        var tiinit = anime.timeline({
            easing: 'linear',
            complete: function(anim) {
                document.querySelector('.intro').classList.add('hidden');

                initBanner();
            }
        });

        anime({
            targets: '.intro .claim-text h1',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        anime({
            targets: '.intro .claim-text h3',
            scale: {
                value: 0
            },
            opacity: 0,
            duration: 0
        });
        tiinit.add({
            targets: '.intro .logo',
            opacity: 1,
            scale: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=200');

        tiinit.add({
            targets: '.intro .claim-text h1',
            opacity: 1,
            scale: {
                value: 1
            },
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=200');

        tiinit.add({
            targets: '.intro .claim-text h3',
            opacity: 1,
            scale: 1,
            easing: 'easeInOutQuart',
            duration: 750
        }, '+=200');

        tiinit.add({
            targets: '.intro',
            opacity: 0,
            scale: 1,
            easing: 'easeInOutSine',
            duration: 450
        }, '+=4000');

        tiinit.play();




    }else{
        initBanner();
    }



    if (document.querySelectorAll("button.navbar-toggler").length > 0) {

        anime({
            targets: '.navbar-collapse li',
            translateX: 800,
            opacity: 0,
            duration: 0
        });

        if (document.querySelectorAll("a.btn-kontakt").length > 0) {
            document.querySelectorAll("a.btn-kontakt")[0].addEventListener("click", function (event) {
                var target = document.querySelectorAll('.navbar-collapse')[0];
                var target3 = document.querySelectorAll("button.navbar-toggler")[0];
                target3.className = (target3.className + " open").replace(/ open open/, "");
                target.className = (target.className + " show").replace(/ show show/, "");
                var target2 = document.querySelectorAll('.navbar')[0];
                target2.className = (target2.className + " open").replace(/ open open/, "");
                anime({
                    targets: '.navbar-collapse svg.muster .shape',
                    opacity: 0,
                    duration: 5,
                });
                anime({
                    targets: '.navbar-collapse li',
                    translateX: 800,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
            });
        }
        // Mobile toggler
        document.querySelectorAll("button.navbar-toggler")[0].addEventListener("click", function (event) {
            var target = document.querySelectorAll('.navbar-collapse')[0];
            this.className = (this.className + " open").replace(/ open open/, "");
            target.className = (target.className + " show").replace(/ show show/, "");
            var target2 = document.querySelectorAll('.navbar')[0];
            target2.className = (target2.className + " open").replace(/ open open/, "");

            if (target.classList.contains("show")) {
                setTimeout(function () {
                    anime({
                        targets: '.navbar-collapse svg.muster .shape',
                        opacity: 1,
                        duration: 200,
                        delay: anime.stagger(50) // increase delay by 100ms for each elements.
                    });
                    anime({
                        targets: '.navbar-collapse li',
                        translateX: 0,
                        opacity: 1,
                        easing: 'easeOutQuad',
                        duration: 300,
                        delay: anime.stagger(60)
                    });
                }, 250);
            } else {
                anime({
                    targets: '.navbar-collapse svg.muster .shape',
                    opacity: 0,
                    duration: 5,
                });
                anime({
                    targets: '.navbar-collapse li',
                    translateX: 800,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
            }
        });
    }

    if (document.querySelectorAll(".angebot-section ").length > 0) {
        [].forEach.call(document.querySelectorAll('.house-section'), function (el, index) {
            axos[index] = document.querySelectorAll('.haus' + index + ' .axo-holder .axo-img img')[0];
            [].forEach.call(document.querySelectorAll('.haus' + index + ' .table-row'), function (e, ind) {

                if (merkliste.indexOf(e.dataset.id) !== -1) {
                    e.classList.add("pinned");
                }
                e.addEventListener("mouseover", function (event) {
                    if (e.dataset.axo)
                        axos[index].src = '/media/' + e.dataset.axo;
                    else {
                        if (axolast[index]) {
                            axos[index].src = '/media/' + axolast[index].dataset.axo;
                        } else {
                            axos[index].src = '/media/' + axos[index].dataset.axo;
                        }
                    }
                })
                e.addEventListener("mouseout", function (event) {
                    if (axolast[index]) {
                        axos[index].src = '/media/' + axolast[index].dataset.axo;
                    } else {
                        axos[index].src = '/media/' + axos[index].dataset.axo;
                    }
                })
            });


            [].forEach.call(document.querySelectorAll('.haus' + index + ' .table-row .btn-pin'), function (e, ind) {

                e.addEventListener("click", function (event) {
                    event.preventDefault();
                    let row = e.parentElement.parentElement;
                    addToList(row.dataset.id);
                    if (row.classList.contains('pinned')) {
                        row.classList.remove("pinned");
                    } else {
                        row.classList.add("pinned");
                    }
                });
            });
            [].forEach.call(document.querySelectorAll('.haus' + index + ' .table-row .btn-kontakt2'), function (e, ind) {
                e.addEventListener("click", function (event) {
                    let row = e.parentElement.parentElement.parentElement.parentElement;
                    var name = row.querySelectorAll('.col-1')[0].innerHTML;
                    var textarea = document.getElementById('yform-formular-field-14');
                    textarea.value = 'Ich interessiere mich für die Wohnung Nr. ' + name + '\n';

                })
            });

            [].forEach.call(document.querySelectorAll('.haus' + index + ' .table-row .btn-open'), function (e, ind) {
                axolast[index] = null;
                e.addEventListener("click", function (event) {
                    event.preventDefault();

                    let row = e.parentElement.parentElement;
                    let $innerDiv = row.getElementsByClassName("inner-div")[0];
                    let $inside = row.getElementsByClassName("inner")[0];
                    let $loader = row.getElementsByClassName("loader")[0];
                    let $grundrissImg = row.getElementsByClassName("grundriss-img")[0];
                    console.log($innerDiv);
                    anime({
                        targets: $grundrissImg,
                        opacity: 1,
                        easing: 'linear',
                        duration: 10
                    });
                    console.log($inside);
                    if (e.classList.contains('active')) {
                        e.classList.remove("active");
                        row.classList.remove("open");
                        anime({
                            targets: $innerDiv,
                            maxHeight: 0,
                            easing: 'linear',
                            duration: 200,
                            complete: function (anim) {
                            }
                        });
                        axolast[index] = null;
                    } else {
                        if (axolast[index]) {
                            let $innerDiv = axolast[index].getElementsByClassName("inner-div")[0];
                            let $btn = axolast[index].getElementsByClassName("btn-open")[0];
                            axolast[index].classList.remove("open");
                            $btn.classList.remove("active");
                            anime({
                                targets: $innerDiv,
                                maxHeight: 0,
                                easing: 'linear',
                                duration: 200,
                                complete: function (anim) {
                                }
                            });
                        }

                        e.classList.add("active");
                        axolast[index] = row;
                        row.classList.add("open");
                        anime({
                            targets: $innerDiv,
                            maxHeight: $inside.scrollHeight,
                            easing: 'linear',
                            duration: $inside.scrollHeight / 2,
                            complete: function (anim) {
                                console.log($grundrissImg.src)
                                if($grundrissImg.src.indexOf('img/weiss.jpg') !== -1) {
                                    anime({
                                        targets: $loader,
                                        opacity: 1,
                                        easing: 'linear',
                                        duration: 50
                                    });
                                    var img = new Image();
                                    img.onload = function () {
                                        $grundrissImg.src = $grundrissImg.dataset.lazy;
                                        anime({
                                            targets: $innerDiv,
                                            maxHeight: $inside.scrollHeight,
                                            easing: 'linear',
                                            duration: $inside.scrollHeight / 2,
                                            complete: function (anim) {
                                            }
                                        });
                                        anime({
                                            targets: $loader,
                                            opacity: 0,
                                            easing: 'linear',
                                            duration: 50
                                        });
                                        anime({
                                            targets: $grundrissImg,
                                            opacity: 1,
                                            easing: 'linear',
                                            duration: 500
                                        });
                                    }
                                    img.src = $grundrissImg.dataset.lazy;
                                }
                            }
                        });
                    }
                })
            });
        });
        if (document.querySelectorAll(".filter-section ").length > 0) {
            //document.querySelector('.badge-merk').innerText = merkliste.length;
            [].forEach.call(document.querySelectorAll('.filter'), function (e, ind) {
                e.addEventListener("change", function (event) {
                    var $f = e.dataset.filter.split(':');

                    var $ar;

                    var name = $f[0];
                    if ($myfilter[name] === undefined) {
                        $ar = [];
                        $myfilter[name] = $ar;
                    } else
                        $ar = $myfilter[name];


                    if (e.checked === true) {
                        var idx2 = $ar.indexOf($f[1]);
                        console.log($ar);
                        if (idx2 < 0) {
                            $ar.push($f[1]);
                        }

                    } else {
                        var idx = $ar.indexOf($f[1]);
                        if (idx >= 0) {
                            $ar.splice(idx, 1);
                        }
                    }
                    console.log($myfilter);
                    hideDetailviews();
                    refreshfilter();
                })
            });
            // register filter refresh


            /* CXM Sliders
            -------------------------------------------------------------------- */
            //custom slider for complex filter RENT
            var rangeMiete = document.getElementById('range-miete');
            var inputMieteFrom = document.getElementById('rentgross_from');
            var inputMieteTo = document.getElementById('rentgross_to');
            if (rangeMiete) {

                var sliderMieteMin = document.getElementById('filteroption-miete-grossto');
                MinValueMiete = sliderMieteMin.getAttribute('data-minrentgross');
                MaxValueMiete = sliderMieteMin.getAttribute('data-maxrentgross');
                MinValueMiete = Number(MinValueMiete);
                MaxValueMiete = Number(MaxValueMiete);
                noUiSlider.create(rangeMiete, {
                    start: [MinValueMiete, MaxValueMiete], // Handle start position
                    step: 1, // Slider moves in increments of '10'
                    margin: 1, // Handles must be more than '20' apart
                    connect: true, // Display a colored bar between the handles
                    direction: 'ltr', // Put '0' at the bottom of the slider
                    orientation: 'horizontal', // Orient the slider vertically
                    behaviour: 'tap-drag', // Move handle on tap, bar is draggable
                    range: { // Slider can select '0' to '100'
                        'min': MinValueMiete,
                        'max': MaxValueMiete
                    },
                    format: {
                        to: function (value) {
                            return Math.round(value) + ' CHF';
                        },
                        from: function (value) {
                            return Math.round(value);
                        }
                    }
                });
                var mieteSliderValues = [
                    document.getElementById('miete-slider-lower-value'),
                    document.getElementById('miete-slider-upper-value')
                ];
                rangeMiete.noUiSlider.on('update', function (values, handle) {
                    mieteSliderValues[handle].innerHTML = values[handle];
                    var value = values[handle];
                    hideDetailviews();
                    refreshfilter();
                });
            }
            //custom slider for complex filter AREA
            var rangeArea = document.getElementById('range-flaeche');
            var inputAreaFrom = document.getElementById('livingspace_from');
            var inputAreaTo = document.getElementById('livingspace_to');
            if (rangeArea) {
                var sliderFlaeche = document.getElementById('filteroption-flaeche');
                MinValueArea = sliderFlaeche.getAttribute('data-minlivingspace');
                MaxValueArea = sliderFlaeche.getAttribute('data-maxlivingspace');
                MinValueArea = Number(MinValueArea);
                MaxValueArea = Number(MaxValueArea);
                noUiSlider.create(rangeArea, {
                    start: [MinValueArea, MaxValueArea], // Handle start position
                    step: 1, // Slider moves in increments of '10'
                    margin: 1, // Handles must be more than '20' apart
                    connect: true, // Display a colored bar between the handles
                    direction: 'ltr', // Put '0' at the bottom of the slider
                    orientation: 'horizontal', // Orient the slider vertically
                    behaviour: 'tap-drag', // Move handle on tap, bar is draggable
                    range: { // Slider can select '0' to '100'
                        'min': MinValueArea,
                        'max': MaxValueArea
                    },
                    format: {
                        to: function (value) {
                            return Math.round(value) + ' m<sup>2</sup>';
                        },
                        from: function (value) {
                            return Math.round(value);
                        }
                    }
                });
                var livingspaceSliderValues = [
                    document.getElementById('flaeche-slider-lower-value'),
                    document.getElementById('flaeche-slider-upper-value')
                ];
                rangeArea.noUiSlider.on('update', function (values, handle) {
                    livingspaceSliderValues[handle].innerHTML = values[handle];
                    hideDetailviews();
                    refreshfilter();
                });
            }

            document.querySelectorAll('.filterreset')[0].addEventListener('click', function () {
                [].forEach.call(document.querySelectorAll('.filter'), function (e, ind) {
                    e.checked = false;
                });
                $myfilter = {};
                rangeArea.noUiSlider.reset();
                rangeMiete.noUiSlider.reset();

                hideDetailviews();
                refreshfilter();
            });

        } else {
            [].forEach.call(document.querySelectorAll('.house-section'), function ($elS, indexS) {
                var num = 0;
                [].forEach.call(document.querySelectorAll('.haus' + indexS + ' li.table-row'), function ($el, index) {

                    if (num % 2 === 0) {
                        //$el.classList.remove('gray');
                    } else {
                        //$el.classList.add('gray');
                    }
                    num++;
                });
            });
        }
    }


    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {

        // fadein main
        var tlinit = anime.timeline({
            easing: 'linear'
        });

        tlinit.add({
            targets: '.lds-dual-ring',
            opacity: 0,
            easing: 'easeInOutSine',
            duration: 50
        }, '+=0');
        /*tlinit.add({
            targets: '.navbar',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 250
        }, '+=0');*/
        tlinit.add({
            targets: 'main',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=200');

        tlinit.add({
            targets: '.scoll-down-icon',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 150
        }, '+=200');


        tlinit.play();


// each image
        [].forEach.call(document.querySelectorAll('.img-reveal'), function (e, index) {
            // scrollmagic scene
            let offset = -300;
            if (index % 2 === 0 && index > 2)
                offset += 100;

            if (!e.classList.contains('hide')) {
                let scene = new ScrollMagic.Scene({triggerElement: e, triggerHook: .6, offset: offset, reverse: false})
                    .setClassToggle(e, "reveal") // add class toggle
                    .on("start", function (ev) {
                        //console.log(e);
                        e.classList.add('reveal-ready')
                    })
                    .addTo(ctrl);
            }

        });





    });


    document.querySelectorAll('.scene').forEach((e, index) => {
        let offset = y / 2;
        if (e.classList.contains('img-reveal')) {
            offset = -1000;
        }
        if (e.classList.contains('bg-img-parallax')) {
            offset = -1000;
        }
        var plinit = anime.timeline({
            easing: 'linear',
            autoplay: false,
            loop: false,
        });
        plinit.add({
            targets: e,
            translateY: [0, 20 * e.getAttribute('data-modifier')],
            duration: 10000
        }, '+=0');
        let scene = new ScrollMagic.Scene({triggerElement: e, offset: offset, duration: "200%"});
        scene.on('progress', function (event) {
            plinit.seek(event.progress * 1000);
        });
        if (x > 560 || !e.classList.contains('img-reveal'))
            scene.addTo(ctrl);
    });

    document.querySelectorAll('.projekte-filter li').forEach((elem) => {
        elem.addEventListener("click", function (event) {
            elem.classList.add('active');
            filterProjects(elem.getAttribute('data-filter'));
        });
    });




    if (document.querySelectorAll('.play-button-outer')[0]) {

        var vid = document.getElementById("myVideo");
        console.log('set events')
        if (vid) {
            console.log('set events')
            vid.onplay = function () {
                console.log('playing')
                isplaying = true;
                 var tlstart = anime.timeline({
                     easing: 'linear'
                 });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 0,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 0,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');
            };
            vid.onended = function () {
                console.log('ended')
                isplaying = false;
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');

            };
            vid.onpause = function () {
                console.log('pause')
                isplaying = false;
            };

        }

        let sound = document.querySelectorAll('.sound-button')[0];
        sound.addEventListener("click", function (event) {

            if (sound.classList.contains("on")) {
                sound.classList.remove('on');
                vid.muted = true;
            } else {
                sound.classList.add('on');
                vid.muted = false;
            }
        });

        document.querySelectorAll('.play-button-outer')[0].addEventListener("click", function (event) {

            if (isplaying) {
                vid.pause();
                // fadein main
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');
            } else {
                vid.play();

                console.log('vid play')
                // fadein main

            }


        });

        document.querySelectorAll('.poster-image')[0].addEventListener("click", function (event) {

            vid.pause();
            // fadein main
            var tlstart = anime.timeline({
                easing: 'linear'
            });
            tlstart.add({
                targets: '.play-button-outer',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 550
            }, '+=0');
            tlstart.add({
                targets: '.poster-image',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 50
            }, '+=0');


        });


    }


})();

// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");
    var scrollI = document.getElementById("scrollIcon");
    if (window.scrollY > 140) {
        nav.classList.add("shrink");
        if (scrollI)
            scrollI.classList.add("hide");
    } else {
        nav.classList.remove("shrink");
    }

    if (document.querySelectorAll(".scroll-top").length > 0) {
        if (window.scrollY > 240) {
            document.querySelectorAll('.scroll-top')[0].classList.add('show');
        } else {
            document.querySelectorAll('.scroll-top')[0].classList.remove('show');
        }
    }
});

// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}

let bounds;
var map = null;
var markers = [];

let projectMarker,iconDotInactive,iconDotActive;


function initMap() {

    if (document.getElementById('map')) {
        bounds = new google.maps.LatLngBounds();


        iconDotInactive = new google.maps.MarkerImage(
            '/resources/img/marker1.png',
            null,
            null,
            null, //size
            new google.maps.Size(25, 22)
        );
        iconDotActive = new google.maps.MarkerImage(
            '/resources/img/marker2.png',
            null,
            null,
            null, //size
            new google.maps.Size(25, 22)
        );

        document.querySelectorAll('.info_map li').forEach((elem,index) => {
            elem.addEventListener('mouseover', function () {
                document.querySelectorAll('.info_map li').forEach((e) => {
                    e.classList.remove('highlight')
                });
                elem.classList.add('highlight');

                markers.forEach(function (iconElem, iconIndex) {
                    if (iconIndex !== 0) {
                        iconElem.setIcon(iconDotInactive);
                        iconElem.set('zIndex', google.maps.Marker.MAX_ZINDEX);
                    }
                });
                markers[index + 1].setIcon(iconDotActive);
                markers[index + 1].set('zIndex', google.maps.Marker.MAX_ZINDEX + 999);
            });

            elem.addEventListener('click',function () {
                showLocation(index + 1)
            });
        });


        // set first marker
        var $firstmarker =  document.querySelector('.info_map .marker');
        var lat = $firstmarker.dataset.lat;
        var lng = $firstmarker.dataset.lng;
        var geojson = [];
        var mycenter = 8.49152105386884;
        if (x > 991) {
            mycenter = 8.49152105386884;
        }
        var mapOptions = {
            zoom: 16,
            center: new google.maps.LatLng(47.26495958516774, 8.65613240918738),
            disableDefaultUI: false,
            styles: [
                {
                    stylers: [
                        {hue: "#464646"},
                        {saturation: -100}
                    ]
                }, {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [
                        {lightness: 100},
                        {visibility: "simplified"}
                    ]
                }, {
                    featureType: "road",
                    elementType: "labels"
                }
            ],
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_BOTTOM
            }
        };

        // Map in Content
        var containerId = 'map';
        map = new google.maps.Map(document.getElementById(containerId), mapOptions);

        // set gray style
        //map.mapTypes.set('gray_map',
        // new google.maps.StyledMapType([{stylers: [{saturation: -90}]}],
        // {name: "Karte"}));
        //map.setMapTypeId('gray_map');

        // set marker when map has loaded
        google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
            setMarkers();
        });
    }
}


function setMarkers() {
    var all_markers = document.querySelectorAll('.info_map .marker');

    const svgMarker = {
        path: "M57.9,28c0-14.9-13.5-27-28.5-27S1,13.1,1,28c0,22.2,22.7,24.6,28.5,58.3\n" +
            "\tC35.2,52.6,57.9,50.2,57.9,28z M20,29.5c0-5.2,4.2-9.5,9.5-9.5s9.5,4.2,9.5,9.5S34.8,39,29.5,39l0,0C24.2,38.9,20,34.7,20,29.5\n" +
            "\tL20,29.5z",
        fillColor: "#5BAACC",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(30, 70),
    };

    projectMarker = new google.maps.Marker({
        position: {lat: 47.26495958516774, lng:   8.65613240918738},
        map: map,
        icon: svgMarker
    });

    markers.push(projectMarker);
    // alert(all_markers.length);
    all_markers.forEach(function (el,index) {
        var lat = el.dataset.lat;
        var lng = el.dataset.lng;
        var markerPos = new google.maps.LatLng(lat, lng);
        bounds.extend(markerPos);
        let markerImage = el.dataset.img;

        if (lat && lng) {
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: map,
                icon: iconDotInactive,
                name: index

            });
            markers[index+1]= marker;
            var listener1 = marker.addListener('mouseover', function () {
                //console.log(this);
                document.querySelectorAll('.info_map li').forEach((e) => {
                    e.classList.remove('highlight')
                });

                markers.forEach(function (iconElem, iconIndex) {
                    if (iconIndex !== 0) {
                        iconElem.setIcon(iconDotInactive);
                    }
                });
                document.querySelector('.marker-name-' + this.name).classList.add('highlight');
                this.setIcon(iconDotActive);
            });
            var listener2 = marker.addListener('mouseout', function () {
                //console.log(this);
                document.querySelector('.marker-name-' + this.name).classList.remove('highlight');
                this.setIcon(iconDotInactive);
            });
        }
    });
}

function showLocation(index) {
    console.log(index);
    map.setZoom(16);
    map.panTo(markers[index].getPosition());
}

// Deletes all markers in the array by removing references to them.
function deleteMarkers() {
    clearMarkers();
    markers = [];
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
    setAllMap(null);
}

// Sets the map on all markers in the array.
function setAllMap(map) {
    for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}
